import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import { type UsageAlertAlertProps, type UsageAlertType } from './UsageAlert';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const UsageAlert = lazy(() => import('./UsageAlert'));

class UsageAlertWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['alert-props', 'alert-type'];
    }

    render() {
        return (
            <RootProviders>
                <UsageAlert
                    alertType={this.prop('alert-type') as UsageAlertType}
                    alertProps={JSON.parse(this.prop('alert-props')) as UsageAlertAlertProps}
                />
            </RootProviders>
        );
    }
}

customElements.define('usage-alert', UsageAlertWebComponent);
