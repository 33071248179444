import type { ShortcutProfile } from './types';
import isMac from '../isMac';

const getShortcutBasedOnOS = (macShortcut: string | string[], winShortcut: string | string[]): string | string[] =>
    isMac() ? macShortcut : winShortcut;

const defaultProfile = {
    active: true,
    id: 3,
    profileName: 'Default',
    shortcuts: {
        close: {
            label: 'Close',
            shortcut: 'Esc',
        },
        confirmAndGoToNext: {
            label: 'Save and go to next',
            shortcut: getShortcutBasedOnOS('Cmd+Enter', 'Ctrl+Enter'),
        },
        toggleAutoReview: {
            label: 'Toggle auto review',
            shortcut: getShortcutBasedOnOS('Cmd+E', 'Ctrl+E'),
        },
        saveEdit: {
            label: 'Save Edit',
            shortcut: getShortcutBasedOnOS('Cmd+S', 'Ctrl+S'),
        },
        saveAndMarkItemAsCompleted: {
            label: 'Save and mark item as completed',
            shortcut: getShortcutBasedOnOS('Cmd+Shift+Enter', 'Ctrl+Shift+Enter'),
        },
        setPlaceholderWrapping: {
            label: 'Set placeholder wrapping',
            shortcut: getShortcutBasedOnOS('Cmd+P', 'Ctrl+P'),
        },
        setAutocomplete: {
            label: 'Set autocomplete',
            shortcut: getShortcutBasedOnOS('Cmd+Shift+A', 'Ctrl+Shift+A'),
        },
        next: {
            label: 'Next',
            shortcut: 'Tab',
        },
        nextAndMarkItemAsCompleted: {
            label: 'Next and mark item as complete',
            shortcut: getShortcutBasedOnOS('Cmd+Tab', 'Ctrl+Tab'),
        },
        previous: {
            label: 'Previous',
            shortcut: 'Shift+Tab',
        },
        previousAndMarkItemAsCompleted: {
            label: 'Previous and mark item as complete',
            shortcut: getShortcutBasedOnOS('Cmd+Shift+Tab', 'Ctrl+Shift+Tab'),
        },
        insertMissingPlaceholderUp: {
            label: 'Insert missing placeholder up',
            shortcut: getShortcutBasedOnOS('Opt+Up', 'Alt+Up'),
        },
        insertMissingPlaceholderDown: {
            label: 'Insert missing placeholder down',
            shortcut: getShortcutBasedOnOS('Opt+Down', 'Alt+Down'),
        },
        insertSource: {
            label: 'Insert source',
            shortcut: getShortcutBasedOnOS('Opt+0', 'Alt+0'),
        },
        firstValueFromMt: {
            label: 'First value from MT',
            shortcut: getShortcutBasedOnOS('Opt+1', 'Alt+1'),
        },
        secondValueFromMt: {
            label: 'Second value from MT',
            shortcut: getShortcutBasedOnOS('Opt+2', 'Alt+2'),
        },
        thirdValueFromMt: {
            label: 'Third value from MT',
            shortcut: getShortcutBasedOnOS('Opt+3', 'Alt+3'),
        },
        fourthValueFromMt: {
            label: 'Fourth value from MT',
            shortcut: getShortcutBasedOnOS('Opt+4', 'Alt+4'),
        },
        firstValueFromTxm: {
            label: 'First value from TMX',
            shortcut: getShortcutBasedOnOS('Cmd+Opt+1', 'Ctrl+Alt+1'),
        },
        secondValueFromTxm: {
            label: 'Second value from TMX',
            shortcut: getShortcutBasedOnOS('Cmd+Opt+2', 'Ctrl+Alt+2'),
        },
        thirdValueFromTxm: {
            label: 'Third value from TMX',
            shortcut: getShortcutBasedOnOS('Cmd+Opt+3', 'Ctrl+Alt+3'),
        },
        fourthValueFromTxm: {
            label: 'Fourth value from TMX',
            shortcut: getShortcutBasedOnOS('Cmd+Opt+4', 'Ctrl+Alt+4'),
        },
        fifthValueFromTxm: {
            label: 'Fifth value from TMX',
            shortcut: getShortcutBasedOnOS('Cmd+Opt+5', 'Ctrl+Alt+5'),
        },
        searchInProject: {
            label: 'Search in project',
            shortcut: getShortcutBasedOnOS('Cmd+F', 'Ctrl+F'),
        },
        searchAndReplaceInProject: {
            label: 'Search and replace in project',
            shortcut: getShortcutBasedOnOS('Cmd+U', 'Ctrl+U'),
        },
        searchTranslationMemory: {
            label: 'Search translation memory',
            shortcut: getShortcutBasedOnOS('Cmd+G', 'Ctrl+G'),
        },
        addTermsToGlossary: {
            label: 'Add terms to glossary',
            shortcut: getShortcutBasedOnOS('Cmd+Shift+F', 'Ctrl+Shift+F'),
        },
        toggleCommentsSidebar: {
            label: 'Open comments sidebar',
            shortcut: getShortcutBasedOnOS('Cmd+Shift+M', 'Ctrl+M'),
        },
        switchTabsRight: {
            label: 'Switch tabs right',
            shortcut: getShortcutBasedOnOS('Ctrl+Opt+Right', 'Ctrl+Alt+Right'),
        },
        switchTabsLeft: {
            label: 'Switch tabs left',
            shortcut: getShortcutBasedOnOS('Ctrl+Opt+Left', 'Ctrl+Alt+Left'),
        },
        insertNextPlaceholder: {
            label: 'Insert next placeholder',
            shortcut: getShortcutBasedOnOS('Cmd+Shift+P', 'Ctrl+Shift+P'),
        },
        highlightNext: {
            label: 'Highlight next suggestion',
            shortcut: getShortcutBasedOnOS('Ctrl+Opt+Down', 'Ctrl+Alt+Down'),
        },
        highlightPrevious: {
            label: 'Highlight previous',
            shortcut: getShortcutBasedOnOS('Ctrl+Opt+Up', 'Ctrl+Alt+Up'),
        },
        insertHighlightedSuggestion: {
            label: 'Insert highlighted suggestion',
            shortcut: getShortcutBasedOnOS('Ctrl+Opt+Enter', 'Ctrl+Alt+Enter'),
        },
        openHelpWindow: {
            label: 'Open help window',
            shortcut: getShortcutBasedOnOS('Cmd+/', 'Ctrl+/'),
        },
        newLineInEditor: {
            label: 'New line in editor',
            shortcut: 'Shift+Enter',
        },
        changeLetterCase: {
            label: 'Change Letter Case',
            shortcut: 'Shift+F3',
        },
        showFileExplorer: {
            label: 'Show file explorer',
            shortcut: getShortcutBasedOnOS('Cmd+J', 'Ctrl+J'),
        },
    },
} as ShortcutProfile;

export default defaultProfile;
