import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import { type ApiToken, type PasswordPolicy, type ProjectWithStats } from './types';
import { convertToTranslationArray, transformMtEngines, transformNotificationSettings } from './utils';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const ProfilePage = lazy(() => import('./Profile'));

class ProfilePageWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'email',
            'token',
            'full-name',
            'team-name',
            'mt-engines',
            'api-tokens',
            'tm-results',
            'avatar-url',
            'unsubscribe',
            'user-fullname',
            'password-policy',
            'glossary-matches',
            'two-factor-enabled',
            'translations-config',
            'notification-settings',
            'password-complex-enough',
        ];
    }

    render() {
        // Personal
        const fullName = this.prop('full-name') ?? '';
        const email = this.prop('email') ?? '';
        const avatarUrl = this.prop('avatar-url') ?? '';
        const twoFactorEnabled = this.prop('two-factor-enabled') === '1';

        const glossaryMatches = Number(this.prop('glossary-matches') || 0);

        const tmResults = Number(this.prop('tm-results') || 0);

        const twigFormatEngines = this.jsonProp<Record<string, number>>('mt-engines') ?? {};
        const engines = transformMtEngines(twigFormatEngines);

        const twigFormatTranslationsConfig = this.jsonProp<Record<string, unknown>[]>('translations-config') ?? [];
        const translations = convertToTranslationArray(twigFormatTranslationsConfig);

        const inlineAIStatus = this.prop('inline-ai-status') === '1';

        // Password Requirements
        const policy = this.jsonProp<PasswordPolicy>('password-policy') ?? ({} as PasswordPolicy);
        const teamName = this.prop('team-name');
        const passwordComplexEnough = this.prop('password-complex-enough') === '1';

        // Reports
        const translationReportProjects = this.jsonProp<ProjectWithStats[]>('translation-report-projects') ?? [];

        // Api Tokens
        const apiTokens = this.jsonProp<ApiToken[]>('api-tokens') ?? [];

        // Notifications props
        const unsubscribe = !!this.prop('unsubscribe');
        const token = this.prop('token') ?? '';
        const userFullname = this.prop('user-fullname') ?? '';

        const twigFormatNotificationSettings =
            this.jsonProp<Record<string, string | null>>('notification-settings') || {};
        const notificationSettings = transformNotificationSettings(twigFormatNotificationSettings);

        return (
            <PageRoot>
                <ProfilePage
                    personal={{
                        email,
                        policy,
                        engines,
                        teamName,
                        fullName,
                        tmResults,
                        avatarUrl,
                        translations,
                        inlineAIStatus,
                        glossaryMatches,
                        passwordComplexEnough,
                    }}
                    reports={{ projects: translationReportProjects }}
                    twoFactorEnabled={twoFactorEnabled}
                    apiTokens={apiTokens}
                    unsubscribe={unsubscribe}
                    token={token}
                    userFullname={userFullname}
                    notificationSettings={notificationSettings}
                />
            </PageRoot>
        );
    }
}

customElements.define('profile-page', ProfilePageWebComponent);
