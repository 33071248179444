import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const OnboardingStepBanner = lazy(() => import('.'));

class OnboardingStepBannerWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <OnboardingStepBanner />
            </RootProviders>
        );
    }
}

customElements.define('onboarding-step-banner', OnboardingStepBannerWebComponent);
