import React, { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const QuickStartChecklist = lazy(() => import('./components/QuickStartChecklist'));

class QuickStartChecklistWebComponent extends ReactWebComponent {
    render() {
        return (
            <RootProviders>
                <QuickStartChecklist />
            </RootProviders>
        );
    }
}

customElements.define('quick-start-checklist', QuickStartChecklistWebComponent);
