import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const CurrentUsage = lazy(() => import('./components/CurrentUsage'));
const UsageOverTime = lazy(() => import('./components/UsageOverTime'));
const UsageOverviewAnalyticsBanner = lazy(() => import('./components/UsageOverviewAnalyticsBanner'));
const UserPilotTracking = lazy(() => import('./components/UserPilotTracking'));

class UsageOverviewWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'keys',
            'keysInBranches',
            'planId',
            'keyLimit',
            'teamProjects',
            'projectLimit',
            'isSeatBased',
            'contributors',
            'contributorLimit',
            'seatsTaken',
            'seatsLimit',
            'gracePeriodSeats',
            'otaLimit',
            'automationUsage',
            'automationLimit',
            'mtCharsUsage',
            'mtCharsLimit',
        ];
    }

    render() {
        const keys = Number(this.prop('keys'));
        const planId = Number(this.prop('planId'));
        const keysInBranches = Number(this.prop('keysInBranches'));
        const keyLimit = Number(this.prop('keyLimit'));
        const projectLimit = Number(this.prop('projectLimit'));
        const isSeatBased = this.prop('isSeatBased') === '1';
        const contributorsCount = Number(this.prop('contributors'));
        const contributorLimit = Number(this.prop('contributorLimit'));
        const seatsTaken = Number(this.prop('seatsTaken'));
        const seatsLimit = Number(this.prop('seatsLimit'));
        const gracePeriodSeats = Number(this.prop('gracePeriodSeats'));
        const otaLimit = Number(this.prop('otaLimit'));
        const automationUsage = Number(this.prop('automationUsage'));
        const automationLimit = Number(this.prop('automationLimit'));
        const mtCharsUsage = Number(this.prop('mtCharsUsage'));
        const mtCharsLimit = Number(this.prop('mtCharsLimit'));

        return (
            <RootProviders>
                <UsageOverviewAnalyticsBanner />
                <CurrentUsage
                    keys={keys}
                    planId={planId}
                    keysInBranches={keysInBranches}
                    keyLimit={keyLimit}
                    projectLimit={projectLimit}
                    isSeatBased={isSeatBased}
                    contributorsCount={contributorsCount}
                    contributorLimit={contributorLimit}
                    seatsTaken={seatsTaken}
                    seatsLimit={seatsLimit}
                    gracePeriodSeats={gracePeriodSeats}
                    otaLimit={otaLimit}
                    automationUsage={automationUsage}
                    automationLimit={automationLimit}
                    mtCharsUsage={mtCharsUsage}
                    mtCharsLimit={mtCharsLimit}
                />
                <UsageOverTime planId={planId} />
                <UserPilotTracking />
            </RootProviders>
        );
    }
}

customElements.define('usage-overview', UsageOverviewWebComponent);
