import { lazy } from 'react';

import { parseTwigJson } from '@app/utils/parseTwigJson';
import { ReactWebComponent } from '@app/utils/web-components';

import type { TranslationPick } from '.';
import { convertMismatchDataDTOtoModel, type TranslationDTO } from '../../../../../../types/translation';

const TranslationIcons = lazy(() => import('.'));
const AppProviders = lazy(() => import('@app/components/AppProviders'));

class TranslationIconsComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'translation',
            'can-ignore-qa',
            'is-html-mismatch-errors-visible',
            'is-placeholders-mismatch-errors-visible',
            'is-editing-translation',
        ];
    }

    render() {
        const isEditingTranslation = this.prop('is-editing-translation') === 'true';

        if (isEditingTranslation) {
            return null;
        }

        const translationProp = this.prop('translation');
        const translationDto = parseTwigJson<TranslationDTO>(translationProp);

        const canIgnoreQATest = this.prop('can-ignore-qa') === 'true';
        const isHtmlMismatchErrorsVisible = this.prop('is-html-mismatch-errors-visible') === 'true';
        const isPlaceholdersMismatchErrorsVisible = this.prop('is-placeholders-mismatch-errors-visible') === 'true';

        if (!translationDto || (!translationDto.qa_results?.length && !translationDto.p_mismatch)) {
            return null;
        }

        const translation: TranslationPick = {
            translationId: parseInt(translationDto.id, 10),
            isPlural: translationDto.is_plural === '1',
            qaResults: translationDto.qa_results,
            mismatchResults: convertMismatchDataDTOtoModel(translationDto.p_mismatch_data),
        };

        const handleQATestIgnored = (test: { testNumber: number }) => {
            this.dispatchEvent(new CustomEvent('onqatestignored', { detail: test.testNumber, bubbles: true }));
        };

        const handleMismatchErrorsIgnored = () => {
            this.dispatchEvent(new Event('onmismatcherrorsignored', { bubbles: true }));
        };

        const handleHtmlPlaceholdersVisibilityChanged = (visible: boolean) => {
            if (visible) {
                this.dispatchEvent(new Event('onmismatchpopovershow', { bubbles: true }));
            }
        };

        return (
            <AppProviders>
                <TranslationIcons
                    translation={translation}
                    canIgnoreQATest={canIgnoreQATest}
                    onQATestIgnored={handleQATestIgnored}
                    isHtmlMismatchErrorsVisible={isHtmlMismatchErrorsVisible}
                    isPlaceholdersMismatchErrorsVisible={isPlaceholdersMismatchErrorsVisible}
                    onMismatchErrorsIgnored={handleMismatchErrorsIgnored}
                    onHtmlPlaceholdersVisibilityChanged={handleHtmlPlaceholdersVisibilityChanged}
                />
            </AppProviders>
        );
    }
}

customElements.define('translation-icons', TranslationIconsComponent);
