import { type ActiveTask, type ActiveTaskDto } from '../types';

const parseActiveTask = (activeTask: ActiveTaskDto | null): ActiveTask | null => {
    if (!activeTask) {
        return null;
    }

    return {
        ...activeTask,
        activeTaskId: activeTask.activeTaskId ? Number(activeTask?.activeTaskId) : undefined,
        activeTaskItemId: activeTask.activeTaskItemId ? Number(activeTask?.activeTaskItemId) : undefined,
        hasActiveTask: activeTask?.hasActiveTask === '1',
        autoCloseItem: activeTask?.autoCloseItem === '1',
    };
};

export default parseActiveTask;
