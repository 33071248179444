import React, { lazy } from 'react';

import emitSocketEvent from '@app/sockets/emitSocketEvent';
import { ReactWebComponent } from '@app/utils/web-components';

import { type CustomTranslationStatusItem } from './components/TranslationActions';
import type { ActiveTaskDto, SiblingSegmentDto } from './types';
import { completeTaskItemSuccess, handleTranslationUpdated } from './utils/completeTaskItemSuccess';
import parseActiveTask from './utils/parseActiveTask';
import parseTranslation from './utils/parseTranslation';
import type { RawSpellchecks } from '../../../../utils/types';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const TranslationEditor = lazy(() => import('.'));
const TranslationEditorProviderWrapper = lazy(() => import('./styles'));

class ReactEditorComponent extends ReactWebComponent {
    static get observedAttributes() {
        return [
            'spellHighlights',
            'activeTask',

            'langId',
            'itemId',
            'autoReview',
            'isReviewingAllowed',
            'isSegmentationEnabled',
            'siblingSegments',
            'characterLimit',
            'qaResults',

            'referenceLanguageId',
            'canIgnoreQATest',
            'translation',
            'isHtmlMismatchErrorsVisible',
            'isPlaceholdersMismatchErrorsVisible',
            'showReviewButton',
            'enableRequestCorrections',
            'showGengoCommentsButton',
            'customTranslationStatuses',

            'currentLockTitle',
            'branchId',
            'rtl',
        ];
    }

    render() {
        const handleQATestIgnored = (test: { testNumber: number }) => {
            this.dispatchEvent(new CustomEvent('onqatestignored', { detail: test.testNumber, bubbles: true }));
        };

        const handleMismatchErrorsIgnored = () => {
            this.dispatchEvent(new Event('onmismatcherrorsignored', { bubbles: true }));
        };

        const handleHtmlPlaceholdersVisibilityChanged = (visible: boolean) => {
            if (visible) {
                this.dispatchEvent(new Event('onmismatchpopovershow', { bubbles: true }));
            }
        };

        const jsonPropOrDefault = <T,>(name: string, defaultValue: T): T => this.jsonProp(name) ?? defaultValue;

        const langId = this.jsonProp<number>('langId');
        const referenceLanguageId = jsonPropOrDefault<number>('referenceLanguageId', 0);
        const canIgnoreQATest = this.prop('canIgnoreQATest') === 'true';
        const isHtmlMismatchErrorsVisible = this.prop('isHtmlMismatchErrorsVisible') === 'true';
        const isPlaceholdersMismatchErrorsVisible = this.prop('isPlaceholdersMismatchErrorsVisible') === 'true';

        const translation = parseTranslation(this.prop('translation'));

        if (!translation || langId === null) {
            return null;
        }

        const branchId = this.jsonProp<number>('branchId');
        const currentLockTitle = this.prop('currentLockTitle') || '';

        const {
            keyId,
            isPlural,
            projectId,
            qaResults,
            mismatchResults,
            segmentNumber,
            translationId,
            activeTask: { taskId, taskItemId, taskItemStatus },
            translationVerified,
            reviewed,
            value,
            sourceValue,
            isBaseLanguage,
        } = translation;

        const rtl = this.jsonProp<number>('rtl') === 1;

        const showReviewButton = jsonPropOrDefault<boolean>('showReviewButton', false);
        const enableRequestCorrections = jsonPropOrDefault<boolean>('enableRequestCorrections', false);
        const showGengoCommentsButton = jsonPropOrDefault<boolean>('showGengoCommentsButton', false);

        const customTranslationStatuses =
            this.jsonProp<{
                enabled: string;
                multipleSelection: string;
                availableStatuses: CustomTranslationStatusItem[];
                enabledStatusIds: number[];
                activeStatusIds: number[];
            }>('customTranslationStatuses') ?? null;

        const itemId = this.jsonProp<number>('itemId');
        const autoReview = jsonPropOrDefault<boolean>('autoReview', false);
        const isReviewingAllowed = jsonPropOrDefault<boolean>('isReviewingAllowed', false);

        const spellCheckMatches = jsonPropOrDefault<RawSpellchecks>('spellHighlights', {});
        const activeTask = parseActiveTask(this.jsonProp<ActiveTaskDto>('activeTask'));
        const siblingSegments = jsonPropOrDefault<SiblingSegmentDto[]>('siblingSegments', []);
        const characterLimit = jsonPropOrDefault<number>('characterLimit', 0);
        const segmentCharacterCount = siblingSegments.reduce(
            (total, segment) =>
                total + (parseInt(segment.segment_number, 10) !== segmentNumber ? segment.translation.length : 0),
            0,
        );

        const handleTaskItemCompleted = (completed: boolean) => {
            if (!activeTask) {
                return;
            }

            const { activeTaskId, activeTaskItemId } = activeTask;
            const activeTaskIdNumber = Number(activeTaskId);
            const activeTaskItemIdNumber = Number(activeTaskItemId);

            emitSocketEvent('task item complete', {
                task_id: activeTaskIdNumber,
                task_item_id: activeTaskItemIdNumber,
                completed,
                projectId,
            });

            document.dispatchEvent(
                new CustomEvent('task_item_complete', {
                    detail: {
                        taskId,
                        taskItemId,
                        completed,
                    },
                }),
            );
        };

        if (!projectId || itemId === null || translationId === null || activeTask === null) {
            return <>Error</>;
        }

        /** TranslationEditorProviderWrapper is added to provide height to AppProviders for bilingual view.
         For translation container with long text, its height should be equal to that of its corresponding base language container when editing is performed.
         Hence, height 100% is needed to be given to all nested containers.
        * */
        return (
            <TranslationEditorProviderWrapper>
                <AppProviders>
                    <TranslationEditor
                        translationId={translationId}
                        value={value}
                        sourceValue={sourceValue}
                        segmentCharacterCount={segmentCharacterCount}
                        characterLimit={characterLimit}
                        languageId={langId}
                        autoReview={autoReview}
                        isBaseLanguage={isBaseLanguage}
                        isReviewingAllowed={isReviewingAllowed}
                        isPlural={isPlural}
                        activeTask={activeTask}
                        onSaveTranslation={handleTaskItemCompleted}
                        itemId={itemId}
                        spellCheckMatches={spellCheckMatches}
                        qaResults={qaResults}
                        keyId={keyId}
                        taskId={taskId}
                        taskItemId={taskItemId}
                        referenceLanguageId={referenceLanguageId}
                        translationVerified={translationVerified}
                        taskItemStatus={taskItemStatus}
                        segmentNumber={segmentNumber}
                        enableRequestCorrections={enableRequestCorrections}
                        showReviewButton={showReviewButton}
                        reviewed={reviewed}
                        showGengoCommentsButton={showGengoCommentsButton}
                        onCompleteTaskSuccess={completeTaskItemSuccess}
                        onTranslationUpdated={handleTranslationUpdated}
                        customTranslationStatuses={
                            customTranslationStatuses && {
                                ...customTranslationStatuses,
                                enabled: customTranslationStatuses.enabled === 'true',
                                multipleSelection: customTranslationStatuses.multipleSelection === 'true',
                            }
                        }
                        canIgnoreQATest={canIgnoreQATest}
                        onQATestIgnored={handleQATestIgnored}
                        mismatchResults={mismatchResults}
                        isHtmlMismatchErrorsVisible={isHtmlMismatchErrorsVisible}
                        isPlaceholdersMismatchErrorsVisible={isPlaceholdersMismatchErrorsVisible}
                        onMismatchErrorsIgnored={handleMismatchErrorsIgnored}
                        onHtmlPlaceholdersVisibilityChanged={handleHtmlPlaceholdersVisibilityChanged}
                        currentLockTitle={currentLockTitle}
                        branchId={branchId}
                        rtl={rtl}
                    />
                </AppProviders>
            </TranslationEditorProviderWrapper>
        );
    }
}

customElements.define('editor-new-root', ReactEditorComponent);
