import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const QuickStartPage = lazy(() => import('./pages/QuickStart'));

class QuickStartPageWebComponent extends ReactWebComponent {
    render() {
        return (
            <PageRoot>
                <QuickStartPage />
            </PageRoot>
        );
    }
}

customElements.define('quick-start-page', QuickStartPageWebComponent);
