import { lazy, Suspense } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const KeyModal = lazy(() => import('.'));
const MainSpinner = lazy(() => import('@app/components/MainSpinner'));

class KeyModalComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['opened', 'edit', 'key-id', 'key-edit-view'];
    }

    render() {
        const isOpen = this.getAttribute('opened') === 'true';
        const isEdit = this.getAttribute('edit') === 'true';
        const keyId = this.getAttribute('key-id') ?? '';
        const keyEditView = parseInt(this.getAttribute('key-edit-view') ?? '1', 10);

        const onClose = () => {
            const event = new Event('close', { bubbles: false });
            this.dispatchEvent(event);
        };

        if (isEdit && !keyId) {
            return null;
        }

        return (
            <RootProviders>
                {isOpen && (
                    <Suspense fallback={<MainSpinner />}>
                        <KeyModal
                            edit={isEdit}
                            keyId={keyId}
                            keyEditView={keyEditView}
                            onClose={onClose}
                            postSuccessLegacyCallback={(response) => {
                                const event = new CustomEvent('after-key-modify', {
                                    detail: {
                                        response,
                                        isEdit,
                                    },
                                });
                                this.dispatchEvent(event);
                            }}
                        />
                    </Suspense>
                )}
            </RootProviders>
        );
    }
}

customElements.define('editor-key-modal', KeyModalComponent);
