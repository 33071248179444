import React, { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const TranslationOrderSummary = lazy(() => import('.'));

class TranslationOrderSummaryView extends ReactWebComponent {
    static get observedAttributes() {
        return ['selected-languages-count'];
    }

    render() {
        const selectedLanguagesCount = parseInt(this.prop('selected-languages-count'), 10) ?? 0;

        return (
            <RootProviders>
                <TranslationOrderSummary selectedLanguagesCount={selectedLanguagesCount} />
            </RootProviders>
        );
    }
}

customElements.define('translation-order-summary', TranslationOrderSummaryView);
