import { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

import type { PreviewLanguage } from './types';
import type { View } from '../../types/preview';

const PageRoot = lazy(() => import('@app/components/PageRoot'));
const ContentPreview = lazy(() => import('.'));

class ContentPreviewWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['file-name', 'languages', 'url'];
    }

    render() {
        const fileName = this.prop('file-name');
        const projectLanguages = this.jsonProp('languages') as PreviewLanguage[];
        const url = this.prop('url');

        const searchParams = new URLSearchParams(window.location.search);
        const view = searchParams.get('view') as View;

        const rawLangParam = searchParams.get('target-lang-id');
        const targetLanguageId = rawLangParam !== null && rawLangParam.length !== 0 ? Number(rawLangParam) : null;

        return (
            <PageRoot>
                <ContentPreview
                    targetLanguageId={targetLanguageId}
                    contentUrl={url}
                    fileName={fileName}
                    projectLanguages={projectLanguages}
                    view={view}
                />
            </PageRoot>
        );
    }
}

customElements.define('content-preview', ContentPreviewWebComponent);
