import type { ReversedShortcuts } from './shortcutRegistry';
import type { Shortcuts } from './types';
import isMac from '../isMac';

type Entries<T> = {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

const getReversedShortcuts = (shortcuts: Shortcuts): ReversedShortcuts => {
    const reversedShortcuts: ReversedShortcuts = {};

    const shortcutsEntries = Object.entries(shortcuts) as Entries<Shortcuts>;
    shortcutsEntries.forEach(([action, { shortcut }]) => {
        if (shortcut === null) return;

        if (Array.isArray(shortcut)) {
            shortcut.forEach((key) => {
                reversedShortcuts[key.toLowerCase()] = action;
            });
        } else {
            reversedShortcuts[shortcut.toLowerCase()] = action;
        }
    });

    return reversedShortcuts;
};

const KEY_REGEX = /(Arrow)/gim;

const CODE_REGEX = /(Arrow|Key|Digit|Numpad)/gim;

const MODIFIERS_KEY = {
    CTRL: 'ctrl',
    ALT: isMac() ? 'opt' : 'alt',
    SHIFT: 'shift',
    CMD: 'cmd',
};

const getProcessedKey = (key: string): string => key.replaceAll(KEY_REGEX, '').toLowerCase();

const getProcessedCode = (key: string): string => key.replaceAll(CODE_REGEX, '').replace('Slash', '/').toLowerCase();

const getUserPressedShortcut = ({ metaKey, ctrlKey, altKey, shiftKey, key, code }: KeyboardEvent): string[] => {
    const result: string[] = [];
    if (!key) {
        return result;
    }

    if (metaKey) result.push(MODIFIERS_KEY.CMD);
    if (ctrlKey) result.push(MODIFIERS_KEY.CTRL);
    if (shiftKey) result.push(MODIFIERS_KEY.SHIFT);
    if (altKey) result.push(MODIFIERS_KEY.ALT);

    result.push(altKey ? getProcessedCode(code) : getProcessedKey(key));

    return result;
};

export { getUserPressedShortcut, getReversedShortcuts };
