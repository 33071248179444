import React, { lazy } from 'react';

import { ReactWebComponent } from '@app/utils/web-components';

const AppProviders = lazy(() => import('@app/components/AppProviders'));
const CharacterCounter = lazy(() => import('.'));

class CharacterCounterWebComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['count', 'limit'];
    }

    render() {
        const countAttr = this.prop('count');
        const limitAttr = this.prop('limit');

        if (!countAttr) {
            return null;
        }

        const count = Number(countAttr) || 0;
        const limit = limitAttr ? Number(limitAttr) : undefined;

        return (
            <AppProviders>
                <CharacterCounter characterCount={count} characterLimit={limit} />
            </AppProviders>
        );
    }
}

customElements.define('character-counter', CharacterCounterWebComponent);
