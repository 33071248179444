import { lazy } from 'react';

import type { ProjectLanguage } from '@app/api/projectLanguages';
import { ReactWebComponent } from '@app/utils/web-components';

import prepareProjectLanguage from './prepareProjectLanguage';
import type { ProjectLanguageServerResponse, SimpleLanguage } from './types';

const RootProviders = lazy(() => import('@app/components/AppProviders'));
const ProjectLanguagesListContainer = lazy(() =>
    import('.').then((module) => ({ default: module.ProjectLanguagesListContainer })),
);

class ProjectLanguagesListComponent extends ReactWebComponent {
    static get observedAttributes() {
        return ['languages', 'is-admin', 'all-languages'];
    }

    render() {
        const languages = this.jsonProp<ProjectLanguageServerResponse[]>('languages');
        const isAdmin = !!this.jsonProp('is-admin');
        const allLanguages = this.jsonProp<SimpleLanguage[]>('all-languages') ?? [];

        const notifyLegacyComponents = (updatedLanguages: ProjectLanguage[]) => {
            document.dispatchEvent(
                new CustomEvent('editor-languages-changed', {
                    detail: {
                        visibleLanguages: updatedLanguages.filter((lang) => !lang.hidden),
                        allLanguages: updatedLanguages,
                    },
                }),
            );
        };

        // in some edge cases languages are not synced, and we need to try again
        document.addEventListener('sync-languages-react', () => {
            if (languages) {
                notifyLegacyComponents(prepareProjectLanguage(languages));
            }
        });

        return (
            <RootProviders>
                <ProjectLanguagesListContainer
                    languages={languages || []}
                    isAdmin={isAdmin}
                    allLanguages={allLanguages}
                    onChange={notifyLegacyComponents}
                />
            </RootProviders>
        );
    }
}

customElements.define('project-languages-list', ProjectLanguagesListComponent);
