import { createContext, type PropsWithChildren, useContext, useMemo } from 'react';

import useIsFeatureEnabled from '@app/hooks/useIsFeatureEnabled';
import useSelector from '@app/hooks/useSelector';
import { getProjectRights, getUserRights } from '@app/selectors';

// TODO: Clean up after permission management
//   Due to the complexity of the logic surrounding glossary conditions while the feature flag exists,
//   this context is exists to unify the conditional rendering of the glossary page
//   based on the user's permissions and the state of the feature flag.
//   As a result, this context might be removed when the feature flag is removed.

type ProjectRights = ReturnType<typeof getProjectRights>;

const legacyPermissionsGlossaryConditionalRendering = (
    isAdmin: boolean,
    usesSharedGlossary: boolean,
    projectRights: ProjectRights,
) => ({
    usesSharedGlossary,
    canReadTerms: true,
    canCreateTerms: true,
    canUpdateTerms: true,
    canDownloadCsv: true,
    canExtractTerms: isAdmin && !usesSharedGlossary && projectRights.glossary === true,
    canDeleteTerm: isAdmin && !usesSharedGlossary && projectRights.glossary === true,
    canDeleteAllTerms: isAdmin && !usesSharedGlossary && projectRights.glossary === true,
    canUploadCsv: isAdmin && !usesSharedGlossary && projectRights.glossary === true,
});

const newPermissionsGlossaryConditionalRendering = (
    usesSharedGlossary: boolean,
    projectRights: ProjectRights,
): GlossaryConditionalRenderingContext => {
    const hasManageGlossaryPermission = projectRights.manageGlossary === true;
    const hasDeleteGlossaryPermission = projectRights.deleteGlossary === true;

    return {
        usesSharedGlossary,
        canReadTerms: true,
        canDownloadCsv: true,
        canCreateTerms: hasManageGlossaryPermission,
        canUpdateTerms: hasManageGlossaryPermission,
        canExtractTerms: hasManageGlossaryPermission,
        canDeleteTerm: !usesSharedGlossary && hasManageGlossaryPermission && hasDeleteGlossaryPermission,
        canDeleteAllTerms: !usesSharedGlossary && hasManageGlossaryPermission && hasDeleteGlossaryPermission,
        canUploadCsv: hasManageGlossaryPermission,
    };
};

type GlossaryConditionalRenderingContext = ReturnType<typeof legacyPermissionsGlossaryConditionalRendering>;

const glossaryConditionalRenderingContext = createContext<GlossaryConditionalRenderingContext | null>(null);

type GlossaryConditionalRenderingContextProviderProps = PropsWithChildren<{
    usesSharedGlossary: boolean;
}>;

export const GlossaryConditionalRenderingContextProvider = ({
    usesSharedGlossary,
    children,
}: GlossaryConditionalRenderingContextProviderProps) => {
    const isNewPermissionsBehaviourEnabled = useIsFeatureEnabled('homeFeatureGranularPermissions');
    const projectRights = useSelector(getProjectRights);

    const { isAdmin } = useSelector(getUserRights);

    const value = useMemo(
        () =>
            isNewPermissionsBehaviourEnabled
                ? newPermissionsGlossaryConditionalRendering(usesSharedGlossary, projectRights)
                : legacyPermissionsGlossaryConditionalRendering(isAdmin, usesSharedGlossary, projectRights),
        [isAdmin, isNewPermissionsBehaviourEnabled, usesSharedGlossary, projectRights],
    );

    return (
        <glossaryConditionalRenderingContext.Provider value={value}>
            {children}
        </glossaryConditionalRenderingContext.Provider>
    );
};

export const useGlossaryConditionalRenderingContext = () => {
    const context = useContext(glossaryConditionalRenderingContext);

    if (!context) {
        throw new Error(
            'useGlossaryConditionalRenderingContext must be used within a GlossaryConditionalRenderingContextProvider',
        );
    }

    return context;
};
